import React, { useState } from 'react';
import axios from 'axios';

function App() {
  const [city, setCity] = useState('');
  const [weather, setWeather] = useState(null);
  const [error, setError] = useState('');

  const getWeather = async () => {
    try {
      const response = await axios.get(`http://127.0.0.1:5000/api/weather?city=${city}`);
      setWeather(response.data);
      setError('');
    } catch (error) {
      setError('Şehir bulunamadı');
      setWeather(null);
    }
  };

  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>Hava Durumu Uygulaması</h1>
      <input
        type="text"
        placeholder="Şehir girin"
        value={city}
        onChange={(e) => setCity(e.target.value)}
      />
      <button onClick={getWeather}>Hava Durumunu Getir</button>

      {error && <p style={{ color: 'red' }}>{error}</p>}

      {weather && (
        <div>
          <h2>{weather.name}</h2>
          <p>Sıcaklık: {weather.main.temp}°C</p>
          <p>Hava Durumu: {weather.weather[0].description}</p>
        </div>
      )}
    </div>
  );
}

export default App;

